<template>
  <q-table
    class="stickyHeader"
    no-data-label="No data"
    loading-label="Loading..."
    :rows="rows"
    :columns="columns"
    :loading="loading"
    :hide-bottom="hideBottom"
    :hide-header="hideHeader"
    :row-key="rows.length > 0 ? rows[0].name : ''"
    v-model:pagination="pagination"
    bordered
    binary-state-sort
    @request="onRequest"
  >
    <template v-slot:top-left v-if="customHeader">
      <slot name="header-left" />
    </template>

    <template v-slot:top-right v-if="customHeader">
      <slot name="header-right" />
      <div class="flex items-center gap-4" v-if="download">
        <q-btn
          color="primary"
          icon="archive"
          label="Export to csv"
          no-caps
          @click="exportTableCSV"
        />
        <q-btn
          color="primary"
          icon="grid_on"
          label="Export to pdf"
          no-caps
          @click="exportTableExcel"
        />
        <q-btn
          color="primary"
          icon="picture_as_pdf"
          label="Export to pdf"
          no-caps
          @click="exportTablePdf"
        />
      </div>
    </template>

    <template v-slot:no-data="{ message }">
      <div class="full-width row flex-center">
        <span class="text-gray font-bold"> {{ message }} </span>
      </div>
    </template>

    <template v-for="(_, slot) of $slots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </q-table>
</template>

<script>
import { ref, watch } from 'vue'
import { EXPORT } from '@/tools'

export default {
  props: {
    rows: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    },
    limit: {
      type: Number,
      default: 10
    },
    page: {
      type: Number,
      default: 1
    },
    total: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    },
    download: {
      type: Boolean,
      default: false
    },
    fileName: {
      type: String,
      default: 'report'
    },
    serverSide: {
      type: Boolean,
      default: false
    },
    hideBottom: {
      type: Boolean,
      default: false
    },
    hideHeader: {
      type: Boolean,
      default: false
    },
    customHeader: {
      type: Boolean,
      default: false
    },
    customBody: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const pagination = ref(
      props.serverSide
        ? {
            page: props.page,
            rowsPerPage: props.limit,
            rowsNumber: props.total
          }
        : {
            rowsPerPage: props.limit
          }
    )

    const onRequest = data => {
      const { page, rowsPerPage } = data.pagination

      pagination.value.page = page
      pagination.value.rowsPerPage = rowsPerPage

      emit('onRequest', data)
    }

    const exportTableCSV = () => {
      EXPORT.exportToCSV({
        header: props.columns,
        data: props.rows,
        filename: props.fileName
      })
    }

    const exportTableExcel = () => {
      EXPORT.exportToExcel({
        header: props.columns,
        data: props.rows,
        filename: props.fileName
      })
    }

    const exportTablePdf = () => {
      EXPORT.exportToPDF({
        header: props.columns,
        data: props.rows,
        filename: props.fileName
      })
    }

    watch(
      () => props.total,
      () => {
        if (props.serverSide) {
          pagination.value.rowsNumber = props.total
        }
      }
    )

    return {
      pagination,
      onRequest,
      exportTableCSV,
      exportTableExcel,
      exportTablePdf
    }
  }
}
</script>

<style lang="scss" scoped>
.stickyHeader {
  max-height: 550px;

  :deep(thead tr th) {
    @apply text-center;
  }

  :deep(thead tr:first-child th) {
    @apply bg-white;
    @apply font-bold;
  }

  :deep(thead tr th) {
    position: sticky;
    z-index: 1;
  }

  :deep(thead tr:first-child th) {
    top: 0;

    &.q-table--loading thead tr:last-child th {
      top: 48px;
    }
  }
}
</style>
