<template>
  <div class="q-mb-md">
    <span class="pageHeaderSmallTitle">{{ breadcrumb }}</span>
    <h4 class="pageHeaderTitle">{{ title }}</h4>
    <span class="pageHeaderSubTitle">{{ subTitle }}</span>
  </div>
</template>

<script>
export default {
  props: ['breadcrumb', 'title', 'subTitle']
}
</script>

<style lang="scss" scoped>
.pageHeaderTitle {
  font-family: 'Open Sans';
  font-size: 32px;
  font-weight: 900;
  margin: 6px 0;
}

.pageHeaderSubTitle {
  font-size: 12px;
  font-style: italic;
  color: $grey;
}

.pageHeaderSmallTitle {
  font-weight: 600;
  font-size: 12px;
  color: $primary;
}
</style>
